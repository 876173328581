var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center image-container"},[_c('div',{staticStyle:{"height":"350px","width":"350px","position":"relative"}},[_c('img',{staticClass:"image",attrs:{"src":_vm.data.photo},on:{"load":function($event){return _vm.onImageLoad()}}})])])]),_c('div',{staticClass:"col-md"},[_c('table',{staticClass:"table mb-3"},[_c('tr',[_vm._m(0),_c('td',[_vm._v(_vm._s(_vm.data.employee_number_id))])]),_c('tr',[_vm._m(1),_c('td',[_vm._v(_vm._s(_vm.data.name))])]),_c('tr',[_vm._m(2),_c('td',[_vm._v(_vm._s(_vm.data.birt_place +', '+ _vm.date(_vm.data.birt_date)))])]),_c('tr',[_vm._m(3),_c('td',[_vm._v(_vm._s(_vm.data.email))])]),_c('tr',[_vm._m(4),_c('td',[_vm._v(_vm._s(_vm.data.mobile_phone))])]),_c('tr',[_vm._m(5),_c('td',[_vm._v(_vm._s(_vm.data.address))])]),_c('tr',[_vm._m(6),_c('td',[_vm._v(_vm._s(_vm.data.department_name))])]),_c('tr',[_vm._m(7),_c('td',[_vm._v(_vm._s(_vm.data.position_name))])]),(_vm.data.department_id == 1)?_c('tr',[_vm._m(8),_c('td',[_vm._v(_vm._s(_vm.data.ihs_number))])]):_vm._e()]),(_vm.manipulateBtn == true)?_c('button',{staticClass:"btn mx-1 btn-success",on:{"click":function($event){return _vm.$router.push({ path: '/human-resource/employee/edit/'+_vm.data.id })}}},[_c('i',{staticClass:"far fa-fw fa-edit"}),_vm._v(" Edit")]):_vm._e(),(_vm.manipulateBtn == true)?_c('button',{staticClass:"btn mx-1 btn-danger",on:{"click":function($event){return _vm.deleteData(_vm.data.id)}}},[_c('i',{staticClass:"far fa-fw fa-trash-alt"}),_vm._v(" Hapus")]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',[_vm._v("Nomor Karyawan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',[_vm._v("Nama")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',[_vm._v("Tempat Tanggal Lahir")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',[_vm._v("Email")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',[_vm._v("Nomor Telepon")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',[_vm._v("Alamat")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',[_vm._v("Departemen")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',[_vm._v("Jabatan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',[_vm._v("Nomor IHS")])])
}]

export { render, staticRenderFns }